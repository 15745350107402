import './Header.css';
import Tooltip from '@material-ui/core/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Icon } from 'uiw';
import fxlogo from '../../assets/favicon/logo.png';
import logo from '../../assets/images/logo.png';
import { logout } from '../../config/actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const avatarUser = document.createElement('img');

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabSelection(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabs: {
    // width: '150px !important',
    // maxWidth: '150px !important',
    // minWidth: '150px !important',
    color: 'rgb(241, 241, 241)',
    fontFamily: "'Inter', sans-serif !important",
    fontSize: '12px',
    fontWeight: 400,
    '&.Mui-selected': {
      color: '#fff ',
      fontWeight: 600,
    },
  },
});

function Header({ listMenu, valueHeader, username }) {
  const [value, setValue] = React.useState(valueHeader);

  const history = useHistory();

  const handleChange = (event, newValue) => {
    history.push('/' + event.currentTarget.textContent.toLowerCase());
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    window.open(`https://access.fluidx.ai`, '_self');
  };

  const classes = useStyles();

  return (
    <div className="Configuration-all">
      <header className="Configuration-header">
        <div className="Configuration-header-left">
          <img
            src={logo}
            className="Configuration-logo"
            alt="logo"
            onClick={() =>
              window.open(`https://access.fluidx.ai/product-selection`, '_self')
            }
          />

          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="#fff"
            variant="scrollable"
            scrollButtons="auto"
          >
            {listMenu &&
              listMenu.map((item) => (
                <Tooltip title={item.tooltip}>
                  <Tab
                    key={item.id}
                    className={classes.tabs}
                    label={item.name}
                    {...tabSelection(item.id)}
                  />
                </Tooltip>
              ))}
          </Tabs>
        </div>

        {username && (
          <div className="Configuration-header-right">
            <div Button-Home-config>
              <HomeIcon
                fontSize="medium"
                className="Button-Home"
                onClick={() =>
                  window.open(
                    `https://access.fluidx.ai/product-selection`,
                    '_self',
                  )
                }
              />
            </div>
            {/* {username && (
          <div className="Configuration-header-right"> */}
            <div style={{ margin: '10px 0 0 0' }}>
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <b>Username: </b>
                    {username[0].toUpperCase() + username.slice(1)}
                    <br />
                    <b>Role: </b>Administrator
                  </span>
                }
                placement="bottom-start"
              >
                <Avatar
                  size="small"
                  icon={<Icon type="user" />}
                  className="Avatar"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                />
              </Tooltip>
            </div>
            <div className="Username">
              {username[0].toUpperCase() + username.slice(1)}
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              onClose={handleClose}
              open={open}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;
