import React from "react"
import { Route, Redirect, Link } from "react-router-dom"
import { getToken } from '../config/token'
import { postRequest } from "../config/requests"

const PrivateRoute = ({component: Component, ...rest}) => {

    const [authentication, setAuthentication] = React.useState(false);
    const [isTokenValidated, setIsTokenValidated] = React.useState(false);

    React.useEffect(() => {}, [authentication, setAuthentication])
  
    React.useEffect(() => {
        let token = getToken()
        if (token) {
            postRequest({token: token, route: "token_validate"})
            .then((res) => { return res.json() })
            .then((json) => {
              if (json.success) { setAuthentication(true) } 
              else { setAuthentication(false) }
            })
            .then(() => setIsTokenValidated(true));
            } else {
                setIsTokenValidated(true); // in case there is no token
            }
    }, [])

    console.log({...rest}.data)
    if (!isTokenValidated) return <div />; // or some kind of loading animation

    return (
        <Route
            {...rest}
            render={(props) => {
                return (authentication && {...rest}.data) ? 
                    <Component {...props}  data={{...rest}.data} setData={{...rest}.setData}/> :
                    <Redirect to="/project" />
            }} />
    )
}

export default PrivateRoute