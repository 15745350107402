export const postRequest = ({values, route, token}) => {
  const content = {
    method: 'POST',
    headers: {
      Accept: "application/json, text/plain, */*", 
      "Content-Type": "application/json"
    },
    contentType: 'application/json; charset=utf-8'
  }
  if (values) {
    content["body"] = JSON.stringify(values)
    content["dataType"] = 'json'
  }
  if (token) {
    content["headers"]["Authorization"] = `Bearer ${token}`
  }
  return fetch("/post/" + route, content)
}

export const getRequest = ({values, route, token}) => {
  const content = {
    method: 'GET',
    dataType: 'json',
    contentType: 'application/json; charset=utf-8'
  }
  if (values) {
    content["body"] = JSON.stringify(values)
  }
  if (token) {
    content["headers"] = {
      Accept: "application/json, text/plain, */*",
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  }
  return fetch("/get/" + route, content)
}