import React from "react"
import { useDispatch } from "react-redux"
import { Route, Redirect, Link } from "react-router-dom"
import LinearProgress from '@mui/material/LinearProgress';
import { setToken } from '../config/token'
import { postRequest, getRequest } from "../config/requests"
import logo from '../assets/images/fluidX.png';
import './DataLoad.css';

const PrivateRouteDataLoad = ({component: Component, ...rest}) => {
    
    const [authentication, setAuthentication] = React.useState(false);
    const [isTokenValidated, setIsTokenValidated] = React.useState(false);
  
    function hanldeAuthentication(bool) {
        setAuthentication(bool)
        setIsTokenValidated(bool)
    }

    React.useEffect(() => {}, [authentication, setAuthentication])
  
    React.useEffect(() => {

        async function dataLoad() {
            let promiseTokenCookie = new Promise((resolve) => {
                try {
                    getRequest({route: "cookie_validate"})
                    .then((res) => resolve(res.json()))
                } catch (e) { 
                    console.log('Error cookie_validate: ', e) 
                    return setIsTokenValidated(true)
                }
            })
        
            let tokenCookieReturn =  await promiseTokenCookie

            if (!tokenCookieReturn.success) {
                return setIsTokenValidated(true)
            } else {
                let promiseTokenValidate = new Promise((resolve) => {
                    try {
                        setToken(tokenCookieReturn.token)
                        postRequest({token: tokenCookieReturn.token, route: "token_validate"})
                        .then((res) => { resolve(res.json()) })
                    } catch (e) { 
                        console.log('Error token_validate: ', e) 
                        return setIsTokenValidated(true)
                    }
                })
            
                let tokenValidateReturn =  await promiseTokenValidate
                if (!tokenValidateReturn.success) {
                    return setIsTokenValidated(true)
                } else {
                    let promiseDataLoad = new Promise((resolve, reject) => {
                        try {
                            getRequest({token: tokenCookieReturn.token, route: "all_data"})
                            .then((res) => { resolve(res.json()) })
                        } catch (e) { 
                            console.log('Error all_data: ', e) 
                            return setIsTokenValidated(true)
                        }
                    })
                
                    let dataLoadReturn =  await promiseDataLoad
                    if (dataLoadReturn.data) {
                        if (!{...rest}.data) {
                            ({...rest}.setData({
                                'username': tokenValidateReturn.data.username,
                                'test': dataLoadReturn.data.test,
                            }))
                        }
                        return hanldeAuthentication(true)
                    } 
                }
            }
        }

        dataLoad().catch('Error wrapping: ', console.error);

    }, [])
    
    if (!isTokenValidated) {
        return (
            <div className="SlowShow">
            <LinearProgress 
                variant="determinate" 
                color="secondary"/>
            <img src={logo} alt="logo" className="ClientLogo" />
            </div>
        )
    } else if (authentication) {
        return (
            <div>Auth</div>
        )
    } else {
        return (
            <Route render={() => { 
                window.location.href = 'https://access.fluidx.ai'
                return null }}/>
        )
    }
}

export default PrivateRouteDataLoad