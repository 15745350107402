import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Ico from './assets/favicon/favicon.ico';
import configureStore from './config/store';
import Header from './features/route/Header';
import Test from './features/Test'
import {listMenu} from "./features/route/ListMenu.js"
import Router from './features/route/Routing';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BDBDBD',
    },
    secondary: {
      main: '#8DB600',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const { store, persistor } = configureStore();
console.log(listMenu)

ReactDOM.render(
  <div>
    <Favicon url={Ico} />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
            <Header listMenu={listMenu} value={5} username={"Tester"}/>
            <Test></Test>
          {<Router store={store} /> }
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </div>,
  document.getElementById('root'),
);