import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRouteDataLoad from "../../components/privateRouteDataLoad"
import PrivateRoute from "../../components/privateRoute"
import {listMenu} from "./ListMenu.js"

export default function Routing() {

  const [data, setData] = React.useState(null);
  console.log(data)
  return (
    <Router>
      <Switch>
        {listMenu
          .filter(m => m.name !== 'Project')
          .map(m => <PrivateRoute 
                      exact path={"/" + m.name.toLowerCase()} 
                      component={m.content} 
                      data={data} setData={setData}/>)
        }
        <PrivateRouteDataLoad path={["/project","/"]} data={data} setData={setData}/>
      </Switch>
    </Router>
  );
}
