// import Admin from '../admin/Admin';
// import Configuration from '../configuration/Configuration';
// import Edit from '../edit/Edit';
// import Loe from '../loe/Loe';
// import Project from '../project/Project';
// import Sow from '../sow/Sow';
// import Statistics from '../statistics/Statistics';

export const listMenu = [
  {
    id: 0,
    name: 'Project',
    tooltip:
      'Set up your project with main information (i.e. location, client information, product details ...)',
    // content: Project,
  },
  {
    id: 1,
    name: 'Configuration',
    tooltip:
      'Configure the content of your projects. Include milestones, travel or other key components to your project.',
    // content: Configuration,
  },
  {
    id: 2,
    name: 'LoE',
    tooltip:
      'Review the summary of the Level of Effort (LoE) needed based on the information you provided on the tabs Project and Configuration.',
    // content: Loe,
  },
  {
    id: 3,
    name: 'Sow',
    tooltip:
      'Review the Statement Of Work (SOW) prebuilt based on the information you provided on the tabs Project and Configuration.',
    // content: Sow,
  },
  {
    id: 4,
    name: 'Statistics',
    tooltip: 'Manage the Level of Efforts (LoE) for each task.',
    // content: Statistics,
  },
  {
    id: 5,
    name: 'Admin',
    tooltip:
      'Manage the prebuild package and the Level of Efforts (LoE) for each task.',
    // content: Admin,
  },
  {
    id: 6,
    name: 'Edit',
    tooltip:
      'Edit tasks you wish to make changes to, add, remove, change position, change color.',
    // content: Edit,
  },
];
