const defaultState = {
    token: null,
    loggedIn: false,
    data: null,
    dataLoaded: false,
  };
  
export default function(state=defaultState, action = {}) {
  switch(action.type) {
    case 'UPDATE_TOKEN':
      return {
        ...state,
        token: action.token
      };
    case "AUTHENTICATION_EXPIRED":
      return defaultState
    case "LOGOUT":
      return defaultState
    default:
      return state;
  }
}